import Tag from "@/components/elements/Tag";

import * as styles from "./ui-kit.module.scss";

function clickAlert() {
  alert("Clicked!!!");
}

function UiDemoPage() {
  return (
    <div className={styles.uiKit}>
      <h1>Ui Demo</h1>
      <h2 className={styles.demoHeading}>Tags</h2>
      <div className={styles.demoContent}>
        <Tag text="Review" />
        <Tag text="Review" size="small" />
        <Tag text="Sustainability" size="small" />
        <Tag text="Sustainability" />
        <Tag text="Sustainability" onClick={clickAlert} />
        <Tag text="Health" />
        <Tag text="Soya" />
        <Tag text="Nutrients" onClick={clickAlert} />
        <br />
        <Tag type="review" />
        <Tag type="event" />
        <Tag type="video" />
        <Tag type="study" onClick={clickAlert} />
        <Tag />
      </div>
      <h2 className={styles.demoHeading}>Another</h2>
      <div className={styles.demoContent}></div>
    </div>
  );
}

export default UiDemoPage;
